import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";

import GMapsAlt from "../components/google-maps-alternative/GMapsAlt";
import i18nContext from "../components/i18n/i18n.context";
import Layout from "../components/layout/Layout";
import Page from "../components/page/Page";
import SEO from "../components/seo/Seo";
import { recaptchaKey } from "../conf/conf";

function GMapsAltPage({ location }) {
  return (
    <i18nContext.Provider value={{ locale: "en" }}>
      <Layout>
        <SEO
          keywords={[
            "google maps",
            "map",
            "map api",
            "google maps alternative",
            "geocoding",
          ]}
          location={location}
          metaDescription="Looking for a Google Maps API alternative? Jawg  Maps offers powerful APIs for static and dynamic maps, geocoding (place search), routing and elevation data."
          title="Google Maps API Alternative"
        />
        <Page>
          <Helmet>
            <script
              src={`https://www.google.com/recaptcha/api.js?render=${recaptchaKey}`}
            />
          </Helmet>
          <GMapsAlt />
        </Page>
      </Layout>
    </i18nContext.Provider>
  );
}

GMapsAltPage.propTypes = {
  location: PropTypes.object.isRequired, //eslint-disable-line react/forbid-prop-types
};

export default GMapsAltPage;
